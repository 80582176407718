<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form__inner">
      <VProfile
        class="mb-40"
        v-model:image="formData.avatar"
        :avatar="avatar"
      >
        <div class="profile__form">
          <VInput
            id="name"
            name="name"
            validator-name="onlyText"
            placeholder="Контактное лицо ФИО*"
            :class-input="['input input-border-all input-placeholder-black input--bg-white']"
            v-model="formData.name"
            :errors="v$.name.$errors"
            :server-errors="serverErrors.value?.name"
            @blur="validateField"
            @input="validateField"
          />

          <VPhone
            type="phone"
            id="phone"
            name="phone"
            placeholder="Номер телефона"
            mask-type
            :class-input="['input input-border-all input-placeholder-black input--bg-white']"
            v-model="formData.phone"
            :errors="v$.phone.$errors"
            :server-errors="serverErrors.value?.phone"
            @blur="validateField"
            @input="validateField"
          />
        </div>
      </VProfile>

      <div class="form__grid form__grid--col-2">
        <VInput
          id="login"
          name="login"
          placeholder="Логин (мин. 3 символа)*"
          :class-input="['input input-placeholder-black']"
          v-model="formData.login"
          :errors="v$.login.$errors"
          :server-errors="serverErrors.value?.login"
          @blur="validateField"
          @input="validateField"
        />

        <VInput
          name="organization_legal_address"
          id="organization_legal_address"
          v-model="formData.organization_legal_address"
          :errors="v$.organization_legal_address.$errors"
          :server-errors="serverErrors.value?.organization_legal_address"
          @blur="validateField"
          @input="validateField"
          :class-input="['input input-placeholder-black']"
          placeholder="Юр. адрес организации*"
        />

        <VInput
          name="organization_actual_address"
          id="organization_actual_address"
          v-model="formData.organization_actual_address"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Почтовый адрес организации"
        />

        <VInput
          name="email"
          id="email"
          placeholder="Адрес электронной почты*"
          :class-input="['input input-placeholder-black']"
          v-model="formData.email"
          :errors="v$.email.$errors"
          :server-errors="serverErrors.value?.email"
          @blur="validateField"
          @input="validateField"
        />

        <VInput
          name="organization_index"
          id="organization_index"
          type="number"
          validator-name="onlyNumber"
          :max-length="6"
          placeholder="Почтовый индекс организации*"
          :class-input="['input input-placeholder-black']"
          v-model="formData.organization_index"
          :errors="v$.organization_index.$errors"
          :server-errors="serverErrors.value?.organization_index"
          @blur="validateField"
          @input="validateField"
        />

        <VInput
          name="msrn"
          id="msrn"
          type="number"
          validator-name="onlyNumber"
          placeholder="ОГРН*"
          :class-input="['input input-placeholder-black', disabledField ? 'disabled' : '']"
          :class-label="disabledField ? 'disabled' : ''"
          :disabled="disabledField"
          v-model="formData.msrn"
          :errors="v$.msrn.$errors"
          :server-errors="serverErrors.value?.msrn"
          @blur="validateField"
          @input="validateField"
        />

        <VInput
          name="kpp"
          id="kpp"
          type="number"
          validator-name="onlyNumber"
          v-model="formData.kpp"
          :errors="v$.kpp.$errors"
          :server-errors="serverErrors.value?.kpp"
          @blur="validateField"
          @input="validateField"
          :class-input="['input input-long input-placeholder-black input-border-bottom', disabledKpp ? 'disabled' : '']"
          :class-label="disabledKpp ? 'disabled' : ''"
          :disabled="disabledKpp"
          placeholder="КПП"
        />

        <VSelect
          classes="select-border-bottom"
          id="partner_type_id"
          name="partner_type_id"
          placeholder="Тип контрагента*"
          :options="listTypeContrAgent"
          v-model="formData.partner_type_id"
          :errors="v$.partner_type_id.$errors"
          :server-errors="serverErrors.value?.partner_type_id"
          @blur="validateField"
          @input="validateField"
        />

        <VInput
          name="arctea"
          id="arctea"
          type="text"
          validator-name="onlyNumber"
          v-model="formData.arctea"
          :errors="v$.arctea.$errors"
          :server-errors="serverErrors.value?.arctea"
          @blur="validateField"
          @input="validateField"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="ОКВЭД*"
        />

        <VSelect
          classes="select-border-bottom"
          id="organization_type_id"
          name="organization_type_id"
          placeholder="Тип организации*"
          :options="listTypeOrganization"
          v-model="formData.organization_type_id"
          :errors="v$.organization_type_id.$errors"
          :server-errors="serverErrors.value?.organization_type_id"
          @blur="validateField"
          @input="(val) => {
            if (val === 2) resetValidateField('msrn')
            validateField
          }"
        />

        <VInput
          name="director"
          id="director"
          validator-name="onlyText"
          placeholder="Руководитель организации*"
          :class-input="['input input-placeholder-black']"
          v-model="formData.director"
          :errors="v$.director.$errors"
          :server-errors="serverErrors.value?.director"
          @blur="validateField"
          @input="validateField"
        />

        <VInput
          name="inn"
          id="inn"
          type="number"
          validator-name="onlyNumber"
          placeholder="ИНН*"
          :class-input="['input input-placeholder-black']"
          v-model="formData.inn"
          :errors="v$.inn.$errors"
          :server-errors="serverErrors.value?.inn"
          @blur="validateField"
          @input="validateField"
        />

        <VInput
          name="trusted_person"
          id="trusted_person"
          placeholder="Действующий по доверенности"
          :class-input="['input input-placeholder-black']"
          v-model="formData.trusted_person"
          :server-errors="serverErrors.value?.trusted_person"
          @blur="validateField"
          @input="validateField"
        />

        <VInput
          name="organization_name"
          id="organization_name"
          placeholder="Сокращенное наименование организации*"
          :class-input="['input input-long input-placeholder-black']"
          v-model="formData.organization_name"
          :errors="v$.organization_name.$errors"
          :server-errors="serverErrors.value?.organization_name"
          @blur="validateField"
          @input="validateField"
        />
      </div>

      <div class="form__subtitle mb-40">Документы</div>
      <VFileList
        v-if="!currentUserIsBidder && getFiles?.length"
        class="mb-20"
        :files="getFiles"
        show-delete
        grid
        @onDelete="handlerDelete"
      />

      <div v-if="currentUserIsBidder">
        <VFile
          v-for="(item, i) of filesTypeList"
          :key="i"
          :title="item.text"
          class="box max-w-initial mb-20"
          :id="item.type"
          :name="item.type"
          icon-name-btn-delete="YellowDelete"
          multi-file
          grid
          push-model-value
          v-model="formData[item.type]"
          :errors="v$[item.type].$errors"
        @onDeleteFile="handlerDelete"
        />
      </div>

      <VFile
        :title="currentUserIsBidder ? 'Прочие документы' : ''"
        class="max-w-initial"
        :class="currentUserIsBidder && 'box'"
        id="files"
        name="files"
        :icon-name-btn-delete="currentUserIsBidder ? 'YellowDelete' : 'DeleteFile'"
        multi-file
        grid
        :push-model-value="currentUserIsBidder"
        v-model="formData.files"
        @onDeleteFile="handlerDelete"
      />

      <div class="form__buttons form__buttons-setting">
        <VButtonLoading
          :is-disabled="isSend"
          :is-errors="v$.$error"
          type="submit"
          color="green"
        >
          Сохранить
        </VButtonLoading>
        <button
          class="btn btn-primary btn-bg-transparent"
          type="button"
          @click="goBack"
        >
          Отмена
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import VFile from '@/components/ui/form/File/VFile'
import VInput from '@/components/ui/form/VInput'
import VPhone from '@/components/ui/form/VMask'
import VProfile from '@/components/profile/VProfile'
import VSelect from '@/components/ui/form/VSelect'
import { goBack } from '@/utils/goRoute'
import { useEditUserForm } from '@/use/Form/PersonalArea/useEditUserForm'
import { ref, computed, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import VFileList from '@/components/list/FileList/VFileList'
import VButtonLoading from '@/components/ui/buttons/VButtonLoading'
import { Alert } from '@/extension/Alert/Alert'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { ORGANIZATIONS, PARTNERS } from '@/utils/manual/manualList'

export default {
  props: {
    avatar: {
      type: [String, null],
      required: false
    }
  },
  setup () {
    const route = useRoute()
    const store = useStore()

    const listTypeOrganization = ref([])
    const listTypeContrAgent = ref([])
    onBeforeMount(async () => {
      try {
        await store.dispatch('user/setUser', route.params.id)
      } catch (e) {
        await Alert.show('error', 'Не удалось загрузить данные о пользователе')
      }
      listTypeOrganization.value = await manualMemoization(false, ORGANIZATIONS)
      listTypeContrAgent.value = await manualMemoization(false, PARTNERS)
    })

    const getFiles = computed(() => {
      return store.getters['files/getFiles']
    })

    const getIsAlert = computed(() => {
      return store.getters.getAlert
    })

    const handlerDelete = (file) => {
      store.commit('files/deleteFile', file)
    }

    return {
      handlerDelete,
      goBack,
      listTypeOrganization,
      listTypeContrAgent,
      getIsAlert,
      ...useEditUserForm(),
      getFiles
    }
  },
  components: {
    VButtonLoading,
    VSelect,
    VPhone,
    VInput,
    VFile,
    VProfile,
    VFileList
  }
}
</script>
