<template>
  <div class="profile">
    <div class="profile__left">
      <div class="profile__avatar">
        <VAvatar
          :image="image"
          :avatar="avatar"
          @uploadAvatar="uploadAvatar"
        />
      </div>
      <div
        v-if="showButtons"
        class="profile__buttons"
      >
        <VLink
          icon="Edit"
          class="btn-link-icon-hover"
          @click="onEdit"
        >
          Редактировать
        </VLink>
        <VLink
          icon="Password"
          class="btn-link-icon-hover"
          :disabled="isDisabled"
          @click="onChangePassword"
        >
          Сменить пароль
        </VLink>
      </div>
    </div>

    <slot/>
  </div>
</template>

<script>
import VAvatar from '@/components/profile/VAvatar'
import VLink from '@/components/ui/buttons/VLink'

export default {
  emits: ['update:image', 'onChangePassword', 'onEdit'],
  props: {
    image: {
      type: Object
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    avatar: {
      type: [String, null],
      required: false
    }
  },

  setup (props, { emit }) {
    const uploadAvatar = file => {
      emit('update:image', file)
    }

    const onEdit = () => {
      emit('onEdit')
    }

    const onChangePassword = () => {
      emit('onChangePassword')
    }

    return {
      uploadAvatar,
      onEdit,
      onChangePassword
    }
  },

  components: {
    VAvatar,
    VLink
  }
}
</script>

<style scoped>

</style>
