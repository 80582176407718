import { reactive } from 'vue'

export function formDataRegisterForm () {
  return reactive({
    email: '',
    password: '',
    confirmPassword: '',
    login: '',
    phone: '',
    name: '',
    inn: null,
    director: '',
    trusted_person: '',
    organization_name: '',
    organization_legal_address: '',
    organization_actual_address: '',
    organization_index: '',
    msrn: '',
    arctea: '',
    partner_type_id: null,
    organization_type_id: null,
    familiar_with_rules: false,
    familiar_with_policy: false,
    kpp: ''
  })
}
