<template>
  <div class="avatar">

    <img v-if="avatar || preview" class="avatar__img" :src="preview ? preview : avatar " :alt="alt">

    <label v-if="$route.fullPath.includes('edit') && !uploadDisabled" class="avatar__label">
      <input
        type="file"
        class="avatar__input"
        accept="image/*"
        @change="uploadAvatar"
      >

      <VIcon class="avatar__input-icon" width="58" height="58" icon-name="Camera" />
    </label>

  </div>
</template>

<script>
import { ref } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'

export default {
  components: { VIcon },
  emits: ['uploadAvatar'],
  props: {
    image: Object,
    alt: {
      type: String,
      required: false,
      default: 'Аватар'
    },
    uploadDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    avatar: {
      type: [String, null],
      required: false
    }
  },
  setup (props, { emit }) {
    const avatar = ref(props.image)
    const preview = ref(null)

    if (avatar.value?.src) {
      preview.value = avatar.value.src
    }

    const uploadAvatar = (e) => {
      const file = e.target.files[0]
      const reader = new FileReader()

      // const url = URL.createObjectURL(file)
      reader.readAsDataURL(file)

      reader.onload = function () {
        preview.value = reader.result
        emit('uploadAvatar', file)
      }

      reader.onerror = function () {
      }
    }

    return {
      uploadAvatar,
      preview
    }
  }
}
</script>
