import { reactive } from 'vue'

export function formDataPersonal(currentUser) {
  return reactive({
    avatar: {},
    login: '',
    name: currentUser.value?.name,
    email: currentUser.value?.email,
    phone: '',
    partner_type_id: null,
    organization_type_id: null,
    trusted_person: '',
    msrn: '',
    arctea: '',
    organization_legal_address: '',
    organization_actual_address: '',
    director: '',
    organization_index: '',
    organization_name: '',
    inn: '',
    files: null,
    kpp: '',
    filesDelete: [],
    files_organization_chart: [],
    files_appointment: [],
    files_proxy: [],
    files_tin: [],
    files_ogrn: [],
    files_usrle: [],
    files_certificate_bank: [],
    files_bank_card: [],
    files_passport: [],
    files_rent: [],
    files_enterprise_map: []
  })
}
