import {
  emailValid,
  innValid,
  loginValid,
  nameValid, ogrnValid,
  okvedValid,
  passwordValid,
  requiredValid, toAcceptCheckbox,
  kppValid
} from '@/utils/validator/fields'
import { helpers, requiredIf } from '@vuelidate/validators'
import { reactive } from 'vue'

export function rulesRegisterForm (formData) {
  return reactive({
    email: emailValid,
    password: {
      ...passwordValid,
      equalPassword: helpers.withMessage('Пароли не совпадают', () => formData.confirmPassword === formData.password)
    },
    confirmPassword: {
      ...passwordValid,
      equalPassword: helpers.withMessage('Пароли не совпадают', () => formData.confirmPassword === formData.password)
    },
    login: loginValid,
    phone: requiredValid,
    name: nameValid,
    inn: innValid,
    kpp: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => formData.partner_type_id === 2)),
      ...kppValid
    },
    organization_name: requiredValid,
    organization_legal_address: requiredValid,
    organization_index: requiredValid,
    arctea: okvedValid,
    director: requiredValid,
    familiar_with_rules: toAcceptCheckbox,
    familiar_with_policy: toAcceptCheckbox,
    partner_type_id: requiredValid,
    organization_type_id: requiredValid,
    msrn: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => formData.organization_type_id === 1 || formData.organization_type_id === null)),
      ...ogrnValid
    }
  })
}
