import { disabledField } from '@/utils/utils'

export function changeKppAndOrgType({ disabledOrg, disabledKpp }, formData) {
  const list = [
    {
      key: disabledOrg,
      condition: formData.organization_type_id === 2
    },
    {
      key: disabledKpp,
      condition: formData.partner_type_id === 1
    }
  ]

  return disabledField(list)
}
