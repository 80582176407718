import { Alert } from '@/extension/Alert/Alert'
import { createFormDataNewFiles, updateFormDataFilesType } from '@/utils/files'
import store from '@/store'
import router from '@/router'
import { setFileUser } from '@/http/user/userApi'
import { unionFiles } from '@/utils/utils'

export async function successResult() {
  store.commit('files/clearQueueFile')
 // await router.push({ name: 'bidder-profile-info' })
  await Alert.show('success', 'Пользователь успешно изменён')
}

export function syncUpdatePersonalInfo({ currentUserIsBidder, formData, response }) {
  if (!currentUserIsBidder.value) {
    formData.files = null
  }

  if (router.currentRoute.value.fullPath.includes('bidder')) {
    store.commit('auth/setUser', response.data)
    store.commit('files/setFiles', response.data.file)
  } else {
    store.commit('user/setUser', response.data)
    store.commit('files/setFiles', response.data.file)
  }

  if (currentUserIsBidder.value) {
    formData.files = response.data.file
    updateFormDataFilesType(formData, response.data)
  }
}

export async function changeFiles({ currentUserIsBidder, formData, userId }) {
  if (currentUserIsBidder.value) {
    const data = createFormDataNewFiles(formData.files, {})
    if (!data) return
    await setFileUser(userId, data)
  } else {
    const data = new FormData()
    unionFiles(formData.files, null, data)
    await setFileUser(userId, data)
  }
}
