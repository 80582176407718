const userFilesTypeLegalEntity = [
  { type: 'files_organization_chart', text: 'Все страницы Устава в последней редакции со всеми изменениями/дополнениями' },
  { type: 'files_appointment', text: 'Действительный Протокол или Действительное Решение о назначении руководителя' },
  { type: 'files_proxy', text: 'Если участник действует по доверенности, то предоставляется либо доверенность, либо страница Устава, где оговаривается его право подписи' },
  { type: 'files_tin', text: 'Свидетельство о постановке на учет в налоговой инспекции' },
  { type: 'files_ogrn', text: 'Свидетельство о государственной регистрации юридического лица' },
  { type: 'files_usrle', text: 'Свидетельство о внесении в ЕГРЮЛ о юридическом лице, зарегистрированном до 1 июля 2002 г (если Контрагент был зарегистрирован до 1.07.2002г)' },
  { type: 'files_certificate_bank', text: 'Справка из банка об открытии счета или копия договора на открытие банковского счета' },
  { type: 'files_bank_card', text: 'Банковская карточка с образцами подписей и печатью исполнительного органа ЮЛ' },
  { type: 'files_enterprise_map', text: 'Карта предприятия' }
]

const userFilesTypeIndividualEntrepreneur = [
  { type: 'files_passport', text: 'Копия паспорта физического лица - индивидуального предпринимателя: 2, 3 страницы и страница, содержащая актуальные данные о месте регистрации' },
  { type: 'files_proxy', text: 'Нотариально удостоверенная доверенность на право заключения договора, в случае, если участник действует по доверенности, с образцами их подписей и копии паспорта (лицевая сторона, прописка)' },
  { type: 'files_tin', text: 'Свидетельство о постановке на учет в налоговой инспекции' },
  { type: 'files_ogrn', text: 'Свидетельство о государственной регистрации индивидуального предпринимателя; ' },
  { type: 'files_bank_card', text: 'Банковская карточка с образцами подписей и печатью ИП' },
  { type: 'files_rent', text: 'Договор аренды/субаренды, либо свидетельство на право собственности (если помещение в собственности)' },
  { type: 'files_enterprise_map', text: 'Карта предприятия' }
]

function getUserFilesTypeList (isLegalEntity) {
  if (isLegalEntity) {
    return userFilesTypeLegalEntity
  }
  return userFilesTypeIndividualEntrepreneur
}

export {
  getUserFilesTypeList
}
