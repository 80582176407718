import {
  emailValid,
  innValid, kppValid,
  loginValid,
  nameValid,
  ogrnValid,
  okvedValid,
  requiredValid
} from '@/utils/validator/fields'
import { helpers, requiredIf } from '@vuelidate/validators'
import { reactive } from 'vue'

export function rulesPersonal(formData, currentUserIsBidder) {
  return reactive({
    login: loginValid,
    name: nameValid,
    email: emailValid,
    phone: requiredValid,
    organization_name: requiredValid,
    director: requiredValid,
    organization_legal_address: requiredValid,
    organization_index: requiredValid,
    arctea: okvedValid,
    inn: innValid,
    partner_type_id: requiredValid,
    organization_type_id: requiredValid,
    msrn: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => formData.organization_type_id === 1 || formData.organization_type_id === null)),
      ...ogrnValid
    },
    kpp: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => formData.partner_type_id === 2)),
      ...kppValid
    },
    // Валидация файлов для участника торгов по типам файлов
    files_organization_chart: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value && formData.partner_type_id === 2))
    },
    files_appointment: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value && formData.partner_type_id === 2))
    },
    files_proxy: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value && formData.trusted_person?.length))
    },
    files_tin: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value))
    },
    files_ogrn: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value))
    },
    files_usrle: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value && formData.partner_type_id === 2))
    },
    files_certificate_bank: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value && formData.partner_type_id === 2))
    },
    files_bank_card: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value))
    },
    files_passport: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value && formData.partner_type_id === 1))
    },
    files_rent: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value && formData.partner_type_id === 1))
    },
    files_enterprise_map: {
      requiredIf: helpers.withMessage('Обязательное поле', requiredIf(() => currentUserIsBidder.value))
    }
  })
}
