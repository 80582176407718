import { watch, ref, computed } from 'vue'
import { useForm } from '@/use/Base/useForm'
import { resetFormData } from '@/utils/utils'
import { FormHandler } from '@/utils/use/formHandler'
import { registration } from '@/http/auth/authApi'
import { formDataRegisterForm } from '@/use/Form/Auth/register/formData.RegisterForm'
import { rulesRegisterForm } from '@/use/Form/Auth/register/rules.RegisterForm'
import { formAfterActionRegister } from '@/use/Form/Auth/register/register.function'
import { Alert } from '@/extension/Alert/Alert'
import { useRouter } from 'vue-router'
import { changeKppAndOrgType } from '@/utils/user/user.helpers'
import { innValid, requiredValid } from '@/utils/validator/fields'

export const checkWatchContragent = (formData, rules) => {
  const getContragent = computed(() => formData.organization_type_id)

  watch(getContragent, () => {
    if (formData.organization_type_id === 2) {
      rules.inn = requiredValid
    } else {
      rules.inn = innValid
    }
  })
}

export function useRegisterForm () {
  const disabledField = ref(false)
  const disabledKpp = ref(false)
  const isCaptcha = ref(false)
  const router = useRouter()

  const formData = formDataRegisterForm()
  const rules = rulesRegisterForm(formData)

  checkWatchContragent(formData, rules)

  watch(formData, () => changeKppAndOrgType({ disabledOrg: disabledField, disabledKpp }, formData), { deep: true })

  const formAction = async () => {
    if (!isCaptcha.value) return
    const data = new FormData()
    const formDataArray = Object.keys(formData)

    formDataArray.forEach((field, index) => {
      if (formDataArray[index] !== 'confirmPassword'
        && formDataArray[index] !== 'familiar_with_rules'
        && formDataArray[index] !== 'familiar_with_policy'
      ) {
        data.append(formDataArray[index], formData[field])
      }
    })

    const response = await registration(data)
    if (response?.status !== 201) {
      throw response
    }
    formData.familiar_with_rules = false
    formData.familiar_with_policy = false
    await router.push({ name: 'login' })
    await Alert.show('success', 'Аккаунт успешно создан')

    resetFormData(formData)
  }

  const verifyCaptcha = () => {
    isCaptcha.value = true
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors, formAfterActionRegister),
    serverErrors: form.serverErrors,
    disabledField,
    disabledKpp,
    isCaptcha,
    verifyCaptcha
  }
}
