import { computed, watch, ref } from 'vue'
import { useForm } from '../../Base/useForm'
import { updatePersonalInfoUser } from '@/http/user/userPersonalInfoApi'
import { setAvatarUser, setFileTypeUser } from '@/http/user/userApi'
import { FormHandler } from '@/utils/use/formHandler'
import { useStore } from 'vuex'
import { deleteFile } from '@/http/otherApi'
import { createArrayFormDataNewFilesType } from '@/utils/files'
import { getUserFilesTypeList } from '@/utils/user/userFilesTypeAccreditation'
import { useRouter } from 'vue-router'
import { formDataPersonal } from '@/use/Form/PersonalArea/formData.personal'
import { rulesPersonal } from '@/use/Form/PersonalArea/rules.personal'
import { changeFiles, successResult, syncUpdatePersonalInfo } from '@/use/Form/PersonalArea/action.personal'
import { changeKppAndOrgType } from '@/utils/user/user.helpers'
import { checkWatchContragent } from '@/use/Form/Auth/register/useRegisterForm'

export function useEditUserForm () {
  const store = useStore()
  const router = useRouter()

  const disabledField = ref(false)
  const disabledKpp = ref(false)

  const currentUser = computed(() => {
    return store.getters['user/getUser']
  })

  const currentUserIsBidder = computed(() => {
    return ['bidder', 'user'].includes(store.getters['user/getUser']?.roles[0]?.name)
  })

  const formData = formDataPersonal(currentUser)
  const rules = rulesPersonal(formData, currentUserIsBidder)

  checkWatchContragent(formData, rules)

  const userIsLegalEntity = computed(() => {
    return formData.partner_type_id === 2
  })

  watch(currentUser, () => {
    const formDataArray = Object.keys(formData)
    const user = store.getters['user/getUser']
    store.commit('files/setFiles', user.file || [])
    formDataArray.forEach((field) => {
      formData[field] = user[field === 'files' ? 'files' : field]
    })
  })

  watch(formData,
    () => changeKppAndOrgType({ disabledOrg: disabledField, disabledKpp }, formData),
    { deep: true }
  )

  const formAction = async () => {
    const queueDeleteFile = store.getters['files/getQueueForDelete']
    const user = store.getters['user/getUser']

    if (queueDeleteFile.length) {
      await deleteFile(queueDeleteFile)
    }

    if (formData.files) {
      await changeFiles({ currentUserIsBidder, formData, userId: user.id })
    }

    const formDataFilesType = createArrayFormDataNewFilesType(formData)
    if (formDataFilesType.length) {
      await setFileTypeUser(user.id, formDataFilesType)
    }

    if (formData.avatar) {
      const avatar = new FormData()
      avatar.append('avatar', formData.avatar)
      await setAvatarUser(user.id, avatar)
    }
    const changeFormData = {
      ...formData
    }

    const response = await updatePersonalInfoUser(user.id, changeFormData)
    syncUpdatePersonalInfo({ currentUserIsBidder, formData, response })
    await successResult(store, router)
  }

  const form = FormHandler(formAction)

  const filesTypeList = computed(() => {
    return getUserFilesTypeList(userIsLegalEntity.value)
  })

  return {
    ...useForm(rules, formData, form.handlerErrors, null, false),
    serverErrors: form.serverErrors,
    disabledField,
    disabledKpp,
    currentUserIsBidder,
    filesTypeList
  }
}
