import { $authHost } from '@/http'
import { EXPAND_USER } from './userApi'

export const updatePersonalInfoUser = async (id, formData) => {
  const { data } = await $authHost.put(`/users/${id}?expand=${EXPAND_USER}`, formData)
  return data
}

export const changePasswordFromPersonalInfo = async (id, formData) => {
  const { data } = await $authHost.patch(`/users/${id}/update-password`, formData)
  return data
}
